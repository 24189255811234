"use client";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
} from "@/components/ui/sidebar";
import { useConversationStore } from "@/app/store/conversationStore";
import { useEffect, useMemo } from "react";
import { Button } from "./ui/button";
import { MessageSquare, Trash2, History } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  getConversationMessages,
  deleteConversation,
} from "@/app/actions/conversation-actions";
import { Separator } from "./ui/separator";
import { motion, AnimatePresence } from "framer-motion";
import { GeistSans } from "geist/font/sans";
import Link from "next/link";


export function AppSidebar() {
  const {
    conversations,
    currentConversationId,
    fetchConversations,
    setCurrentConversationId,
  } = useConversationStore();

  // Limit the number of conversations displayed in the sidebar
  const maxSidebarConversations = 10;
  const limitedConversations = useMemo(() => {
    return conversations.slice(0, maxSidebarConversations);
  }, [conversations]);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  useEffect(() => {
    // Add event listener for conversation refresh
    const handleRefreshConversations = () => {
      fetchConversations();
    };
    if (typeof window !== "undefined") {
      window.addEventListener(
        "refreshConversations",
        handleRefreshConversations,
      );
    }

    // Cleanup
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener(
          "refreshConversations",
          handleRefreshConversations,
        );
      }
    };
  }, [fetchConversations]);

  const loadConversation = async (conversationId: string) => {
    try {
      setCurrentConversationId(conversationId);
      const messages = await getConversationMessages(conversationId);

      // Dispatch an event to notify the main UI to update
      const event = new CustomEvent("conversationLoaded", {
        detail: { messages, conversationId },
      });
      if (typeof window !== "undefined") {
        window.dispatchEvent(event);
      }
    } catch (error) {
      console.error("Error loading conversation:", error);
    }
  };

  const handleDelete = async (e: React.MouseEvent, conversationId: string) => {
    e.stopPropagation();
    try {
      await deleteConversation(conversationId);
      fetchConversations();
      if (currentConversationId === conversationId) {
        setCurrentConversationId(crypto.randomUUID());
        if (typeof window !== "undefined") {
          window.dispatchEvent(new CustomEvent("newConversation"));
        }
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  return (
    <Sidebar>
      <SidebarHeader className="pb-1">
        <h1 className="text-xl ml-4 mb-0 mt-1">
          <span className="font-jetbrains-mono">agao.ai</span>
        </h1>
      </SidebarHeader>
      <Separator className="my-2" />
      <SidebarContent>
        <SidebarGroup>
          <h2 className="px-4 text-md font-bold mb-2">Chat History</h2>
          <AnimatePresence>
            {limitedConversations.map((conversation, index) => (
              <motion.div
                key={conversation.id}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
                transition={{ duration: 0.15, delay: index * 0.05 }}
                className="group/conversation relative"
              >
                <Button
                  variant="ghost"
                  className={cn(
                    "w-full justify-start gap-2 pr-8",
                    currentConversationId === conversation.id && "bg-accent",
                  )}
                  onClick={() => loadConversation(conversation.id)}
                >
                  <MessageSquare
                    size={12}
                    className="text-muted-foreground flex-shrink-0 flex-grow-0"
                  />
                  <span className={`${GeistSans.className} truncate font-normal`}>
                    {conversation.title || "New Conversation"}
                  </span>
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute right-1 top-1/2 -translate-y-1/2 h-6 w-6 p-0 opacity-0 group-hover/conversation:opacity-100 transition-opacity"
                  onClick={(e) => handleDelete(e, conversation.id)}
                >
                  <Trash2
                    size={12}
                    className="text-muted-foreground hover:text-destructive"
                  />
                </Button>
              </motion.div>
            ))}
          </AnimatePresence>
          
          <div className="">
            <Link href="/history" passHref>
              <Button 
                size="sm"
                variant="ghost" 
                className=" pl-4 w-full flex justify-start gap-2 text-muted-foreground hover:text-foreground"
              >
                <History size={14} />
                <span className={GeistSans.className}>View History</span>
              </Button>
            </Link>
          </div>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter />
    </Sidebar>
  );
}
