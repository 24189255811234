"use client";

import { StreamableValue, useStreamableValue } from "ai/rsc";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Copy, Loader2, ChevronDown, BotMessageSquare } from "lucide-react";
import { useState, useEffect, useContext } from "react";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { useChat } from "@/app/contexts/ChatContext";
import React from "react";
import { ChatContext } from "@/app/contexts/ChatContext";

function CodeBlock({
  children,
  className,
}: {
  children: string;
  className?: string;
}) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative group/code">
      <pre className={className}>
        <code>{children}</code>
      </pre>
      <button
        onClick={handleCopy}
        className="copy-btn absolute -bottom-2 -right-6 p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 opacity-0 group-hover/code:opacity-100 transition-opacity"
        title="Copy code"
      >
        <Copy
          className={`h-3 w-3 ${copied ? "text-foreground" : "text-gray-500"}`}
        />
      </button>
    </div>
  );
}

function MarkdownSection({ content }: { content: string }) {
  const [copied, setCopied] = useState(false);
  const { sendMessage } = useChat();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="group/section ">
      {content.length > 0 && (
        <div className="mx-3 mt-4 markdown-section">
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{
              code({ children, className }) {
                if (className) {
                  return (
                    <CodeBlock className={className}>
                      {children as string}
                    </CodeBlock>
                  );
                }
                return <code className={className}>{children}</code>;
              },
              a({ href, children }) {
                if (href?.startsWith("#option-")) {
                  return (
                    <>
                      <Button
                        variant="outline"
                        className="mr-2 mt-2 flex"
                        onClick={() => {
                          sendMessage(children as string);
                        }}
                      >
                        {children}
                      </Button>
                    </>
                  );
                }
                return <a href={href}>{children}</a>;
              },
            }}
          >
            {content}
          </Markdown>
          <div className=" opacity-0 group-hover/section:opacity-100 transition-opacity">
            <button
              onClick={handleCopy}
              className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
              title="Copy section"
            >
              <Copy
                className={`h-3 w-3 ${
                  copied ? "text-foreground" : "text-gray-500"
                }`}
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export function AIMessage(props: {
  value: StreamableValue<string>;
  className?: string;
  type?: "thought" | "response";
}) {
  const [data, metadata] = useStreamableValue(props.value);
  const sections = data?.split(/\n\s*[-_*]{3,}\s*\n/) || [];
  
  // Create a reference to track the data for changes
  const dataRef = React.useRef<string | undefined>(undefined);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
  
  // Get isLoading from ChatContext
  const chatContext = useContext(ChatContext);
  const chatIsLoading = chatContext?.isLoading || false;
  
  // Create a ref for the accordion content container
  const accordionContentRef = React.useRef<HTMLDivElement>(null);
  
  // Determine the current agent state for the badge
  const determineAgentState = () => {
    if (!processedData) return { label: "Initializing...", color: "bg-blue-100 text-blue-800" };
    
    // If the chat is loading but this message is done streaming, it means we're waiting for a response
    if (chatIsLoading && !isStreaming) return { label: "Waiting...", color: "bg-yellow-100 text-yellow-800" };
    
    if (!isStreaming) return { label: "Completed", color: "bg-green-100 text-green-800" };
    
    if (processedData.includes("Tool result:")) {
      return { label: "Using Tools", color: "bg-purple-100 text-purple-800" };
    } else if (processedData.includes("Tool input:")) {
      return { label: "Calling Tool", color: "bg-yellow-100 text-yellow-800" };
    } else if (processedData.includes("Stream:")) {
      return { label: "Streaming", color: "bg-orange-100 text-orange-800" };
    } else {
      return { label: "Thinking", color: "bg-blue-100 text-blue-800" };
    }
  };
  
  // Check for specific stream completion marker
  useEffect(() => {
    if (data && data.includes("🔄 STREAM_COMPLETE 🔄")) {
      setIsStreaming(false);
    }
  }, [data]);
  
  // Track when data changes to detect stream activity
  useEffect(() => {
    if (data !== undefined && data !== dataRef.current) {
      // Data has changed, we're streaming
      dataRef.current = data;
      setIsStreaming(true);
      
      // Auto-open the accordion when new data arrives
      // setIsAccordionOpen(true);
      
      // Set a timeout to detect when streaming stops
      const timeoutId = setTimeout(() => {
        // If data hasn't changed since the last check (after 1 second), consider stream complete
        if (data === dataRef.current) {
          setIsStreaming(false);
        }
      }, 1000);
      
      return () => clearTimeout(timeoutId);
    }
  }, [data]);
  
  // Scroll accordion content to bottom when new data arrives and accordion is open
  useEffect(() => {
    if (isAccordionOpen && accordionContentRef.current) {
      // Check if data has changed
      const dataChanged = data !== dataRef.current;
      
      // Wait a tiny bit for the content to render
      setTimeout(() => {
        if (accordionContentRef.current) {
          // Set scroll position to bottom without using scrollIntoView
          accordionContentRef.current.scrollTop = accordionContentRef.current.scrollHeight;
        }
      }, 10);
    }
  }, [data, isAccordionOpen]);
  
  // Also ensure scrolling happens when accordion is opened
  useEffect(() => {
    if (isAccordionOpen && accordionContentRef.current) {
      // Set scroll position to bottom when accordion is opened
      setTimeout(() => {
        if (accordionContentRef.current) {
          accordionContentRef.current.scrollTop = accordionContentRef.current.scrollHeight;
        }
      }, 50); // Slightly longer timeout to ensure content is fully rendered
    }
  }, [isAccordionOpen]);
  
  // Also check if metadata.done is true (fallback)
  useEffect(() => {
    if (metadata && (metadata as any).done === true) {
      setIsStreaming(false);
    }
  }, [metadata]);
  
  // Initial streaming state when component mounts
  useEffect(() => {
    // If we have data when component mounts, start in streaming state
    if (data && data.length > 0) {
      setIsStreaming(true);
      dataRef.current = data;
    }
  }, []);

  // Process data to remove markers before displaying
  const processedData = data ? data.replace("🔄 STREAM_COMPLETE 🔄", "") : "";
  // Process sections without markers as well
  const processedSections = processedData?.split(/\n\s*[-_*]{3,}\s*\n/) || [];
  
  // Get the current agent state for the badge
  const agentState = determineAgentState();

  if (props.type === "thought") {
    // Only show thought stream if it has meaningful content
    const hasToolUsage = processedData && (
      processedData.includes("Tool input:") || 
      processedData.includes("Tool result:") || 
      processedData.includes("Using tool:") || 
      processedData.includes("Stream:") ||
      processedData.includes("💭")
    );
    
    // If there's no meaningful content, don't render the thought stream at all
    if (!hasToolUsage) {
      return null;
    }
    
    if (processedData && processedData?.split("\n").length > 2) {
      return (
        <div className={`${props.className || ""} text-sm border rounded-lg overflow-hidden shadow-sm mb-4 mx-2`}>
          {/* Accordion Header */}
          <button 
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            className="w-full p-4 flex items-center justify-between transition-colors"
          >
            <div className="flex items-center gap-3">
              {isStreaming ? (
                <Loader2 className="animate-spin w-4 h-4" />
              ) : chatIsLoading ? (
                <Loader2 className="animate-spin w-4 h-4 " />
              ) : (
                <BotMessageSquare className="w-4 h-4" />
              )}
              <span className="font-medium">Agent</span>
              <span className={`text-xs px-2 py-0.5 rounded-full ${agentState.color}`}>
                {agentState.label}
              </span>
            </div>
            <div className="flex items-center gap-1 text-xs opacity-70">
              <span>{isAccordionOpen ? "Hide details" : "Show details"}</span>
              <ChevronDown className={`w-4 h-4 transition-transform duration-200 ${isAccordionOpen ? 'rotate-180' : ''}`} />
            </div>
          </button>
          
          {/* Accordion Content with Animation */}
          <div 
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              isAccordionOpen ? 'max-h-[20rem] opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <div 
              ref={accordionContentRef}
              className="p-3 border-t border-border overflow-y-auto max-h-[20rem] scroll-smooth"
            >
              {processedData?.split("\n").map((line, i) => (
                <div key={i}>{line}</div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <div
      className={`empty:hidden rounded-lg max-w-[600px] space-y-4 message-container ${
        props.className || ""
      }`}
    >
      {processedSections.map((section, index) => (
        <div key={index} className="w-full">
          <MarkdownSection content={section.trim()} />
          {index < processedSections.length - 1 && <Separator className="mt-4 " />}
        </div>
      ))}
    </div>
  );
}
