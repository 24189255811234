"use client";

import * as React from "react";
import { Loader2 } from "lucide-react";
import { GeistMono } from 'geist/font/mono';


export function StepLoader({ text }: { text: string }) {
  return (
    <div className="flex items-center gap-2 p-4 py-2 text-muted-foreground">
      <Loader2 size={12} className="animate-spin" />
      <small className="font-jetbrains-mono">{text}</small>
    </div>
  );
}
